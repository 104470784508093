import { withRouter } from 'next/router';
import { LocalizationProvider } from 'ilenia';
import { ThemeProvider } from 'styled-components';

import { SeoHead } from '@bscom/seo';
import { projectTheme } from '@bscom/styling/old-brand-theme';

function Application({
  Component,
  translations,
  pageProps = {},
  cookies,
  router,
  ...props
}) {
  return (
    <LocalizationProvider locale={props.localeCode} translations={translations}>
      <SeoHead
        localeCode={props.localeCode}
        subdomain=""
        hasAlternateDomains={false}
        createSubdomainUrl={(subdomain) =>
          `https://investors.trustpilot.com${router.asPath || ''}`
        }
      />
      <ThemeProvider theme={projectTheme}>
        <Component {...props} {...pageProps} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

Application.getInitialProps = async ({ Component, ctx }) => {
  const localeCode = 'en-US';

  const enLibTranslations = await import(
    `@trustpilot/businesssite-components/localisation/en/strings.json`
  );

  const enTranslations = await import('../localisation/en/strings.json');

  const translations = {
    ...enLibTranslations,
    ...enTranslations,
  };

  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    ...pageProps,
    translations,
    localeCode: localeCode,
  };
};

export default withRouter(Application);
